import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  BackendError,
  Comarca,
  ServicioContacto,
  ServicioProgramado,
} from "../models";
//import { ServicioProfesionalListado } from "./ServiciosListadoModule";

@Module({ namespaced: true, name: "ServiciosProgramadosModule" })
export default class ServiciosProgramadosModule extends VuexModule {
  errors: BackendError | null = null;
  servicios: ServicioProgramado[] = [];
  servicio: ServicioProgramado | null = null;
  contacto: ServicioContacto | null = null;
  comarcas: Comarca[] = [];
  //profesionales: ServicioProfesionalListado[] = [];
  isLoading = false;

  @Action
  fetchComarcas() {
    this.setLoad(true);

    const url = `/comarcas`;
    return ApiService.get(url)
      .then(({ data }) => {
        this.setComarcaData(data);
        this.setLoad(false);
      })
      .catch((err: Error | AxiosError) => {
        this.setLoad(false);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchById({ formularioId }: { formularioId: string }) {
    this.setLoad(true);

    const url = `/servicios-programados-abiertos/${formularioId}`;
    return ApiService.get(url)
      .then(({ data }) => {
        this.setDetailData(data);
        this.setLoad(false);
      })
      .catch((err: Error | AxiosError) => {
        this.setLoad(false);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchByComarcaId({ comarcaId }: { comarcaId: string }) {
    this.setLoad(true);

    const url = `/servicios-programados-abiertos`;

    const query = comarcaId
      ? ApiService.get(url, {
          params: {
            filter: {
              include: [{ relation: "comarca" }],
              where: {
                comarcaId: { eq: comarcaId },
              },
            },
          },
        })
      : ApiService.get(url, {
          params: {
            filter: {
              include: [{ relation: "comarca" }],
            },
          },
        });

    return query
      .then(({ data }) => {
        data.sort((a, b) => (a.comarca.nombre > b.comarca.nombre ? 1 : -1));
        this.setData(data);
        this.setLoad(false);
      })
      .catch((err: Error | AxiosError) => {
        this.setLoad(false);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  update({
    comarcaId,
    id,
    servicio,
  }: {
    comarcaId: string;
    id: string;
    servicio;
  }) {
    const url =
      comarcaId == null
        ? `/servicios-programados/${id}`
        : `/comarcas/${comarcaId}/servicios-programados/${id}`;

    return ApiService.put(url, servicio).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setData(data) {
    this.servicios = data;
  }

  @Mutation
  setContacto(data) {
    this.contacto = data;
  }

  @Mutation
  setDetailData(data) {
    this.servicio = data;
  }
  @Mutation
  setComarcaData(data) {
    this.comarcas = data;
  }

  @Mutation
  setLoad(isLoading) {
    this.isLoading = isLoading;
  }

  /*@Mutation
  setProfesional(data) {
    this.profesionales = data;
  }*/
}
